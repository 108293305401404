.popup{
	position: fixed; 
    margin: auto;
	width: 100%;
	height: 100%; 
	top: 0; 
	right: 0; 
	bottom: 0; 
	left: 0; 
    background: rgba(0,0,0,0.4); 
    z-index: 99999;  
    overflow-y: auto;
    display: none;
    .popup-inner{
    	width: 100%;
    	background: #fff;
    	margin:0 auto;
        padding: 80px 88px 61px 114px;
    	position: relative;
        border-radius: 10px;
        top: 50%;
        z-index: 9999;
        max-width: 1170px;
        height: auto;
        &.small {
            max-width: 327px;
        }
        @include transform(translateY(-50%));
        .content-pop{
            padding: 0;
            flex: 0 0 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 auto;
            width: 100%;
            .images{
                width: 275px;
            }
            .content{
                width: 653px;
                // height: 586px;
                height: unset;
                overflow-y: auto;
                padding-right: 30px;
                h3{
                    margin: 0;
                    margin-bottom: 5px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 39px;
                    color: #0F232F;
                }
                h4{
                    font-style: normal;
                    font-weight: bold;
                    line-height: 150%;
                    color: #0F232F;
                    opacity: 0.7;
                    margin: 0;
                }
                p{
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: #0F232F;
                    opacity: 0.8;
                    margin: 0;
                }
                .more-info{
                    margin-top: 47px;
                    h5{
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 150%;
                        color: #0F232F;
                        opacity: 0.7;
                        margin-bottom: 12px;
                    }
                    .list-info{
                        display: flex;

                    }
                    a{
                        margin-right: 60px;
                        display: flex;
                        align-items: center;
                        &:last-child{
                            margin-right: 0;
                        }
                        span{
                            width: 40px;
                            height: 40px;
                            border-radius: 100%;
                            background: #D9D9D9;;
                            position: relative;
                            display: inline-block;
                            margin-right: 10px;
                            img{
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                right: 0;
                                margin: auto;
                                border-radius: unset;
                                filter: brightness(0) invert(1);
                            }
                        }
                    }
                }
            }
        }
    }
    .close-popup{
    	position: absolute;
    	right: 10px;
    	top: 10px;
    	img{
    		transition: .2s all ease-out;
    	}
    	&:hover{
    		img{
    			transform:rotate(90deg);
    		}
    	}
    }
    .overlay {position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; z-index: 3;}
    &#pop-resource{
        .popup-inner{
            height: auto;
            .content-pop{
                .content{
                    height: auto;
                }
            }
        }
        .download{
            h5{ margin-bottom: 5px; }
            button{
                display: block;
                width: 100%;
                line-height: 50px;
                font-weight: 400;
                background: #EF3E56;
                border-color: #EF3E56;
                text-transform: capitalize;
                &:hover{
                    opacity: .8;
                }
            }
        }
    }
}

@media (max-width: 1279px) {
    .popup{
        .popup-inner{
            max-width: 816px;
            padding: 60px 40px;
            .content-pop{
                .images{
                    width: auto;
                    flex: 0 0 160px;
                    max-width: 160px;
                    position: relative;
                    &:after{
                        content:"";
                        position: absolute;
                        right:-20px;
                        top: 0;
                        width: 1px;
                        height: 60%;
                        background: #D9D9D9;
                    }
                }
                .content{
                    width: auto;
                    flex: 0 0 calc(100% - 160px);
                    max-width: calc(100% - 160px);
                    padding-left: 40px;
                    padding-right: 0px;
                    box-sizing: border-box;
                    position: relative;
                    max-height: 500px;
                    .more-info{
                        .list-info{
                            flex-flow: row wrap;
                            a{
                                flex: 0 0 100%;
                                max-width:100%;
                                margin-right: 0px;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
        &#pop-resource{
            .popup-inner{
                .images{
                    flex: 0 0 258px;
                    max-width: 258px;
                    &:after{ display: none; }
                }
                .content{
                    flex: 0 0 calc(100% - 258px);
                    max-width: calc(100% - 258px);
                }
            }   
        }
    }
}

@media (max-width: 1023px) {

    .popup{
        .popup-inner{
            max-width: unset;
            margin: 0 65px;
            width: auto;
            .content-pop{
                .content{ max-height:400px;  }
            }
        }
        &#pop-resource{
            .popup-inner{
                .images{
                    flex: 0 0 180px;
                    max-width: 180px;
                }
                .content{
                    flex:0 0 calc(100% - 180px);
                    max-width:calc(100% - 180px);
                    padding-left: 25px;
                }
                .content-pop{
                    .content{
                        h3{ font-size: 24px; line-height: 34px; }
                    }
                }
            }
            .download{
                button{
                    width: 100%;
                    font-size: 14px;
                    line-height: 30px;
                    padding: 0 20px;
                    height: auto;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .popup{
        .popup-inner{
            padding: 40px 24px;
            margin: 40px 30px 40px;
            top: 0;
            transform: translateY(0);
            .content-pop{
                flex-flow: row wrap;
                position: relative;
                .images,.content{ 
                    flex:0 0 100%; max-width: 100%; 
                    padding: 0px;
                }
                .images{
                    &:after{ display: none; }
                    img{
                        max-height: 150px;
                        overflow: hidden;
                    }
                }
                .content{
                    max-height: unset;
                    overflow:auto;
                    margin-bottom: 20px;
                    p{
                        max-height: 130px;
                        overflow: auto;
                        font-size: 12px;
                    }
                    .more-info{
                        margin-top: 20px;
                        .list-info{
                            a{ 
                                flex: 0 0 50%; max-width: 50%; font-size: 12px;
                                color: #0F232F; text-decoration: none;
                            }
                        }
                    }
                    h3{ font-size: 18px; line-height: 1; margin-bottom: 10px; }
                    h4{ font-size: 16px; line-height: 1; margin-bottom: 15px; }
                    h5{ font-size: 12px; }
                }
            }
        }
        &#pop-resource{
            .popup-inner{
                .images{
                    flex: 0 0 100%;
                    max-width: 100%;
                    img{
                        max-height: 190px;
                    }
                }
                .content{
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-left: 0px;

                }
                .content-pop{
                    .content{
                        h3{
                            font-size: 18px;
                            line-height: 1.4;
                        }
                        br{ display: none; }
                    }
                }
            }
            .download{
                flex-flow: row wrap;
                margin: 0 -5px;
                .column{
                    padding: 0px 5px;
                }
            }
        }

    }
}