/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
@import "../helper/sass-lib";

/* general
----------------------------------------------------------------------------------------------*/
@import "../component/layout";
@import "../milligram/Color";
@import "../helper/popup";

.banner-home {
    padding-top: 130px;
    .wrap-slider {
        overflow: hidden;
        border-radius: 8px;
    }
    .slider-img {
        height: 530px;
        .slick-list,
        .slick-track,
        .slick-slide,
        .slick-slide > div,
        .list {
            height: 100%;
        }
        figure {
            height: 100%;
            img {
                height: 100%;
                width: 100%;
                display: block;
                object-fit: cover;
            }
        }
    }
    .bot-slider {
        background: #0f232f;
        padding: 40px 0 40px 260px;
        box-sizing: border-box;
        position: relative;
        .list {
            height: 100%;
            .wrap-text {
                display: flex;
                .text {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
            h1 {
                margin-bottom: 0px;
                font-size: 1.188rem;
                box-sizing: border-box;
                color: #fff;
            }
            h6 {
                margin-bottom: 0px;
                font-size: 1rem;
                margin-right: 50px;
                box-sizing: border-box;
                color: #fff;
            }
            .opacity {
                h6 {
                    opacity: 0.7;
                }
            }
        }
        .slick-dots-container {
            position: absolute;
            left: 50px;
            top: 45px;
            width: 160px;
            overflow: hidden;
            display: block;
            > ul {
                padding: 0;
                display: flex;
                transition: all 0.25s;
                position: relative;
                margin: 0;
                list-style: none;
                transform: translateX(0);
                align-items: center;
                bottom: unset;
                height: 100%;
            }
        }
        .slick-dots {
            li {
                display: inline-block;
                list-style: none;
                margin: 0 4px;
                &.slick-active {
                    button {
                        width: 40px;
                        background: #ef3e56;
                    }
                }
            }
            button {
                font-size: 0px;
                padding: 0px;
                width: 16px;
                height: 8px;
                background: #4b5a63;
                border-radius: 0px;
                border: none;
                outline: 0;
                transition: 0.2s all ease-out;
            }
        }
    }
}
.home {
    padding: 70px 0 115px;
    .title-home {
        padding-top: 15px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 48px;
            height: 4px;
            background: $color-secondary;
        }
        h1 {
            font-size: 2.737rem;
            font-weight: 600;
        }
        h1,
        h2 {
            font-weight: 600;
            font-size: 2rem;
        }
        b {
            display: block;
            font-size: 2.5rem;
        }
    }
    &.about {
        figure {
            img {
                width: 100%;
            }
        }
    }
    &.embed-medsos {
        .wrap-twitter,
        .wrap-ig {
            max-height: 545px !important;
            overflow-y: auto;
            iframe {
                position: relative !important;
            }
        }
    }
    &.masonry {
        .grid {
            width: auto;
            margin: 0 -15px;
            &:after {
                content: "";
                clear: both;
                display: block;
            }
            .grid-item {
                width: 33.333%;
                display: block;
                float: left;
                margin-bottom: 30px;
                padding: 0 15px;
                transition: 0.2s opacity ease-out;
                figure {
                    overflow: hidden;
                    img {
                        filter: grayscale(50%);
                        opacity: 0.5;
                        transition: 0.2s all ease-out;
                    }
                }
                .thumb-video {
                    overflow: hidden;
                    img {
                        transition: 0.2s all ease-out;
                    }
                }
                .text {
                    color: #fff;
                    padding: 16px;
                    box-sizing: border-box;
                    h5 {
                        margin-bottom: 10px;
                        font-weight: 600;
                    }
                    h6 {
                        margin-bottom: 0px;
                    }
                }
                .inner {
                    border-radius: 8px;
                    overflow: hidden;
                }
                &.black {
                    .inner {
                        background: $color-primary;
                    }
                }
                &.red .inner {
                    background: $color-secondary;
                }
                &.yellow .inner {
                    background: $color-tertiary;
                }
                &:hover .inner {
                    opacity: 0.8;
                    figure {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .thumb-video {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
                .wrap-video {
                    .thumb-video {
                        position: relative;
                        height: 220px;
                    }
                    .ic-play {
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        width: 56px;
                        height: 56px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100%;
                        background: rgba(255, 255, 255, 0.8);
                        cursor: pointer;
                    }
                    .wrap-yt {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 100%;
                        display: none;
                        iframe {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
            .grid-sizer {
                width: 33.333%;
            }
        }
    }
    &.maps {
        .center {
            text-align: center;
            .title-home {
                &:before {
                    right: 0;
                    margin: 0 auto;
                }
            }
        }
        .grid-tabing {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            a {
                font-size: 1.313rem;
                padding: 0 25px;
                color: rgba(15, 35, 47, 0.5);
                transition: 0.2s all ease-out;
                &:hover {
                    color: $color-primary;
                }
                &.active {
                    color: $color-primary;
                }
            }
        }
        .box-drag {
            position: relative;
            overflow: auto;
            figure {
                width: 1170px;
                height: 455px;
                position: relative;
                &.blue,
                &.red,
                &.yellow {
                    .poin {
                        display: none;
                    }
                }
                &.blue .blue,
                &.red .red,
                &.yellow .yellow {
                    display: block;
                }
            }
            .poin {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .our-project {
            padding: 0 100px;
            margin-top: 40px;
            .wrap-bullet {
                display: flex;
                justify-content: flex-end;
                span {
                    flex: 0 0 173px;
                    max-width: 173px;
                    height: 173px;
                    border-radius: 100%;
                    background: $color-secondary;
                    display: flex;
                    flex-flow: column wrap;
                    justify-content: center;
                    text-align: center;
                    align-items: center;
                    margin: 0 15px;
                    color: #fff;
                    font-size: 57px;
                    font-weight: 600;
                    small {
                        text-transform: uppercase;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.popup {
    &#pop-subscribe {
        position: fixed;
        bottom: 60px;
        right: 20px;
        width: 325px;
        z-index: 2;
        border: 1px solid #0f232f;
        background: #fff;
        padding: 60px 40px;
        box-shadow: 0 0 20px -15px #000;
        justify-content: center;
        text-align: center;
        border-radius: 8px;
        margin: 0;
        height: auto;
        top: auto;
        left: auto;
        display: none;
        figure,
        figcaption,
        .input-email {
            flex: 0 0 100%;
            max-width: 100%;
        }
        figure {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
        }
        figcaption {
            font-size: 12px;
            color: #0f232f;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 20px;
            display: block;
        }
        .close-popup {
            position: absolute;
            right: 10px;
            top: 10px;
        }
        .input-email {
            input {
                height: 55px;
                line-height: 55px;
                padding-left: 55px;
                font-size: 12px;
                background: url("/images/material/pencil.svg") no-repeat 10px
                    center;
                background-size: 33px;
            }
            button {
                background: #f7b448;
                color: #000;
                font-size: 13px;
                border-radius: 8px;
                height: 40px;
                line-height: 40px;
                margin-bottom: 0px;
                border: none;
                font-weight: 600;
                padding: 0 70px;
                text-transform: capitalize;
            }
        }
    }
}
@media (min-height: 731px) and (max-height: 746px) {
    .banner-home {
        .wrap-slider {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            height: calc(100vh - 130px);
            max-height: calc(100vh - 130px);

            .slider-img {
                flex: 1 1 300px;
                height: 300px;
            }

            .bot-slider {
                flex: 0 0 125px;
                height: 125px;
            }
        }
    }
}
@media all and (max-width: 1500px) {
    // .banner-home{
    // 	.slider-img{
    // 		height: 350px;
    // 	}
    // }
    .home.maps .our-project {
        padding: 0 50px;
    }
}

@media all and (max-width: 1279px) {
    .home {
        padding: 70px 0;
        &.maps {
            .our-project {
                padding: 0px;
                margin-top: 50px;
                .wrap-bullet {
                    span {
                        flex: 0 0 119px;
                        max-width: 119px;
                        height: 119px;
                        font-size: 40px;
                        small {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        &.masonry {
            .grid {
                .grid-sizer {
                    width: 50%;
                }
                .grid-item {
                    width: 50%;
                    figure {
                        img {
                            display: block;
                            width: 100%;
                        }
                    }
                    .wrap-video {
                        .thumb-video {
                            overflow: hidden;
                            > img {
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 1023px) {
    .home {
        .title-home {
            h1,
            h2 {
                font-size: 1.75rem;
            }
            b {
                font-size: 2.25rem;
            }
        }
        &.maps {
            overflow: hidden;
            .grid-tabing {
                overflow-x: auto;
                padding: 0 0 20px;
                margin-right: -16px;
                justify-content: flex-start;
                a {
                    padding: 0;
                    margin: 0 20px 0 0;
                    display: block;
                    width: auto;
                    white-space: nowrap;
                }
            }
        }
        &.embed-medsos {
            .wrap-twitter,
            .wrap-ig {
                max-height: 500px !important;
            }
        }
    }
    .banner-home {
        .slider-img {
            height: 364px;
        }
        .bot-slider {
            padding: 60px 30px 25px;
            .slick-dots-container {
                top: 30px;
                left: 30px;
            }
            .list {
                .wrap-text {
                    margin: 0 -5px;
                    flex-flow: row wrap;
                    max-width: 406px;
                    .text {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: 0 5px;
                        box-sizing: border-box;
                        margin-bottom: 15px;
                        h6 {
                            font-size: 16px;
                        }
                        &:last-child {
                            margin-bottom: 0px;
                            h6 {
                                font-size: 14px;
                            }
                        }
                    }
                }
                h6 {
                    margin-right: 0px;
                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}

@media all and (max-width: 767px) {
    .banner-home {
        padding-top: 70px;
        .slider-img {
            height: 225px;
        }

        .bot-slider {
            padding: 45px 15px 25px;
            .slick-dots {
                button {
                    height: 4px;
                }
            }
            .slick-dots-container {
                top: 20px;
                left: 15px;
            }
        }
    }
    .home {
        padding: 40px 0;
        .title-home {
            h1,
            h2 {
                font-size: 1.5rem;
            }
            b {
                font-size: 32px;
            }
        }
        &.about {
            .row {
                .column.column-50 {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
        &.maps {
            .our-project {
                flex-flow: row nowrap;
                .column.column-50 {
                    margin-bottom: 40px;
                }
                .wrap-bullet {
                    justify-content: flex-end;
                    span {
                        flex: 0 0 80px;
                        max-width: 80px;
                        height: 80px;
                        font-size: 32px;
                        margin: 0 5px;
                        line-height: 1;
                    }
                }
            }
            .grid-tabing a {
                font-size: 16px;
            }
        }
        &.masonry {
            .grid {
                .grid-sizer,
                .grid-item {
                    width: 100%;
                    float: none;
                }
            }
        }
        &.embed-medsos {
            padding: 0px 0 40px;
            .row {
                flex-wrap: wrap;
                .column.column-50 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            .wrap-twitter,
            .wrap-ig {
                max-height: 400px !important;
                margin: 20px 0px;
                iframe {
                    max-width: 100% !important;
                }
            }
        }
    }
    .popup#pop-subscribe {
        left: 0;
        top: auto;
        right: 0;
        bottom: 0;
        margin: 0px auto 40px;
        width: 300px;
        padding: 40px 30px;
        z-index: 999999;
    }
}

@media all and (max-width: 600px) {
    .banner-home {
        .slider-img {
            height: 180px;
        }
        .bot-slider {
            padding: 30px 15px 25px;
        }
    }
}
