header{
	height: 125px;
	padding: 30px 0px;
	box-sizing: border-box;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	z-index: 999;
	transition: .2s all ease-out;
	border-bottom: 1px solid transparent;
	&.float{
		border-bottom: 1px solid #D9D9D9;	
	}
	.wrapper{
		display: flex;
	}
	.right-header{
		margin-left: auto;
		nav{
			ul{
				display: flex;
				margin: 0 -15px;
				li{
					list-style: none;
					padding: 0 24px;
					position: relative;
					margin-bottom: 15px;
					&.dropdown {
						&:hover { 
							> ul { display: block; }
						}
						> ul { display: none; left: 50%; min-width: 134px; padding-top: 16px; position: absolute; top: 100%; @include transform(translateX(-40%)); z-index: 99;
							 	li { 
							 		background: #fff; 
							 		display: block; 
							 		margin-bottom: 0; 
							 		padding: 12px 12px; 
							 		>a { 
							 			font-size: 16px; 
							 			font-weight: 300;
							 			&:hover, &.active { font-weight: 600; }
							 		}
							 		&:first-child { border-top: 2px solid #EF3E56; padding-top: 16px; }
							 	}
						}
					}
					a{
						color: #0F232F;
						transition: .2s all ease-out;
						font-size: 1.188rem;
					}
					&:hover,&.active{
						>a{
							font-weight: 600;
							color: #EF3E56;
						}
					}
				}
			}
			.mobile-only{
				display: none;
			}
		}
		.wrap{
			display: flex;
			justify-content: flex-end;
			.box-search{
				position: relative;
				flex: 0 0 260px;
				max-width: 260px;
				margin-right: 30px;
				input{ margin-bottom: 0px; }
				input[type='text']{
					border: none;
					height: 30px;
					line-height: 30px;
					border-bottom: 1px solid #0F232F;
					border-radius:unset;
					padding: 0;
					padding-right: 30px;
					font-size: 16px;
					color: #000;
					&:focus{
						border-color: #F7B448;
					}
				}
				input[type='submit']{
					border:none;
					border-radius: unset;
					position: absolute;
					right: 0;
					top: 0;
					right: 0;
					bottom: 0;
					font-size: 0;
					width: 24px;
					height: 24px;
					background: url('/images/material/ic-search.svg')no-repeat center;
					background-size: contain;
					padding: 0px;
				}
				::placeholder {
				  font-size: 13px;
				  font-weight: 300;
				  font-family: "Montserrat";
				}
			}
			.wrap-btn{
				a{
					background: #F7B448;
					padding: 0 25px;
					box-sizing: border-box;
					color: #000;
					font-weight: 600;
					border-radius: 8px;
					display: inline-block;
					line-height:35px;
					font-size: 13px;
					transition: .2s all ease-out;
					&:hover{
						background: #D78809;
						color: #fff;
					}
				}
			}
		}
	}
}

footer{
	background:#0F232F;
	padding: 80px 0 30px;
	color:#fff;
	position: relative;
	.foot-top{
		display: flex;
		figure{
			flex: 0 0 135px;
			max-width: 135px;
			margin-right: 60px;	
		}
		.col{
			flex: 0 0 295px;
			max-width: 295px;
		}
		.subscribe{
			flex: 0 0 400px;
			max-width: 400px;
			margin-left: auto;
			small{
				font-size: 11px;
				opacity: .6;
				display: block;
				margin-top: 5px;
			}
			.search{
				display: flex;
				height: 40px;
				border-radius: 8px;
				overflow: hidden;
				input{ 
					height: 100%; 
					border: none;
					border-radius: 0px;
					margin-bottom: 0px;
				}
				input[type='text']{
					background: #fff;
				}
				input[type='submit']{
					flex: 0 0 105px;
					max-width: 105px;
					background: #F7B448;
					color: #0F232F;
					font-size: 13px;
					padding: 0px;
					line-height: 40px;
					text-transform: capitalize;
					letter-spacing: 1px;
					font-family: 'Montserrat';
					font-weight: 600;
				}

			}
		}
	}
	.foot-mid{
		display: flex;
		justify-content: center;
		padding: 40px 0px;
		border-top: 1px solid rgba(255,255,255,.1);
		border-bottom: 1px solid rgba(255,255,255,.1);
		margin: 40px 0px;
		a{
			margin: 0 35px;
			display: flex;
			align-items: center;
			span{
				width: 40px;
				height: 40px;
				border-radius: 100%;
				background: #fff;
				position: relative;
				display: inline-block;
				margin-right: 10px;
				transition: .2s all ease-out;
				img{
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					right: 0;
					margin: auto;
				}
			}
			&:hover{
				color: #F7B448 !important;
				span{
					background: #F7B448;
				}
			}
		}
	}
	.foot-bot{
		display: flex;
		justify-content: space-between;
		p{ margin-bottom: 0px; }
		a{
			position: relative;
			padding-right: 10px;
			margin-right: 5px;
			display: inline-block;
			font-weight: 400;
			box-sizing: border-box;
			-webkit-transition: -webkit-transform 200ms ease-in-out;
			-moz-transition: -moz-transform 200ms ease-in-out;
			-o-transition: -o-transform 200ms ease-in-out;
			transition: transform 200ms ease-in-out;
			&:after{
				content:'';
				width: 1px;
				height: 100%;
				background: #fff;
				position: absolute;right: 0;
				top: 0;
				bottom: 0;
			}
			&:last-child{
				&:after{
					display: none;
				}
			}
			&:focus{
				color: #fff;
			}
			&:hover{
				color: #EF3E56;
			}
		}
	}
	h6{
		margin-bottom: 5px;
		font-size: 1.053rem;
	}
	p{ 
		font-size: 13px; 
		font-weight: 400;
		line-height: 1rem;
		margin-bottom: 5px;
	}
	a{
		color: #fff;
		font-size: 13px;
	}
	.togglemenu-mobile{ display: none; }
}

@media all and (max-width: 1279px) {
	header .right-header nav ul li a {
		font-size: 16px;
	}
	footer{
		.foot-mid{
			a{ margin: 0 35px; }
		}
	}
}

@media all and (max-width: 1023px) {
	header{
		height: 117px;
		padding: 33px 0px;
		.wrapper{ align-items: center; }
		.logo-header{
			flex: 0 0 95px;
			max-width: 95px;	
		}
		.right-header{
			flex: 0 0 calc(100% - 95px);
			max-width: calc(100% - 95px);
			padding-left: 80px;
			nav{
				position: absolute;
				top: 100%;
				right: 0;
				width: 228px;
				display: none;
				box-shadow: 0 3px 20px -15px #000;
				ul{
					background: #fff;
					flex-flow: row wrap;
					justify-content: flex-end;
					width: 100%;
					padding-right: 0px;
					margin: 0px;
					overflow-y: auto;
					max-height: 450px;
					li{
						flex: 0 0 100%;
						max-width: 100%;
						text-align: right;
						padding: 20px 40px;
						border-bottom: 1px solid rgba(217,217,217,.4);
						margin-bottom: 0px;
						&:last-child{border-bottom:none;}
						a{
							font-size: 16px;
							font-weight: 600;
							color: #0F232F;
							display: block;
						}
						&.dropdown{
							> ul{
								position: relative;
								left: 0;
								top: 0;
								width: 100%;
								min-width: 100%;
								transform: translateX(0);
								padding-top: 0px;
								border:none;
								display: none;
								li{
									display: block;
									border: none;
									&:first-child{
										border:none;
									}
									a{
										font-size: 14px;
									}
								}
							}
						}
					}
				}
			}
			.wrap{
				align-items: center;
				justify-content: flex-start;
				width: 100%;
				.box-search{
					margin-right: 20px;
					form{ margin-bottom: 0px; }
				}
				.wrap-btn{ 
					flex: 0 0 130px; max-width: 130px; 
					a{ 
						font-size: 13px;
						white-space: nowrap; 
						width: 100%;
						text-align: center;
						padding: 0 15px;
					}
				}
				.togglemenu-mobile{
					display: flex;
					justify-content: space-between;
					flex-flow: column wrap;
					align-items: flex-end;
					margin-left: auto;
					flex: 0 0 23px;
					max-width: 23px;
					cursor: pointer;
					span{
						width: 100%;
						height: 3px;
						margin: 2px 0px;
						border-radius: 8px;
						background: #757171;
						transition: .2s all ease-out;
					}
					&.active{
						span{
							background:#F7B448;
							&:nth-child(2){
								width: 70%;
							}
						}
					}
				}
			}
		}
	}
	footer{
		.wrapper{ margin: 0 40px; }
		.foot-top{
			flex-flow: row wrap;
			.subscribe{
				flex: 0 0 400px;
				max-width: 400px;
				margin-top: 20px;
				margin-left: 0px;
			}
		}
		.foot-mid{
			a{ margin: 0 15px; }
		}
		.foot-bot{ align-items: baseline; }
	}
}

@media all and (max-width: 767px) {
	header{
		padding: 15px 0px;
		height: 55px;
		.wrapper{
			margin: 0 16px;
		}
		.right-header{
			flex: 0 0 calc(100% - 50px);
			max-width: calc(100% - 50px);
			.wrap-btn{
				display: none;
			}
			.wrap{
				justify-content: flex-end;
				.box-search{
					flex: 0 0 160px;
					max-width: 160px;
					margin-right: 0px;
					input[type=submit]{
						width: 18px;
						height: 18px;
						background-size: contain;
						margin: auto 0;
					}
				}
				.togglemenu-mobile{ margin-left: 30px; }
			}
			nav{
				ul{
					max-height: 252px;
					overflow-y: auto;
					li{
						padding: 10px 25px;
						&:last-child{
							border-bottom: 1px solid rgba(217, 217, 217, 0.4);
						}
						a{ font-size: 13px; }
						&.dropdown{
							ul{
								li{
									padding: 5px;
									a{ font-size: 13px;}
								}
							}
						}
					}
				}
				.mobile-only{
					display: block;
					background: #fff;
					text-align: center;
					padding: 10px 0;
					a{
						background: #F7B448;
						font-size: 12px;
						font-weight: 600;
						color: #000;
						padding: 0 20px;
						display: inline-block;
						line-height: 40px;
						border-radius: 8px;
					}
				}
			}
		}
		.logo-header{
			flex: 0 0 50px;
			max-width: 50px;
		}
	}
	footer{
		padding: 40px 0px 20px;
		h6{ font-size: 16px; }

		.wrapper{
			margin: 0 25px;
		}
		.foot-mid{
			padding: 25px 0px;
			a{ 
				font-size: 0px; 
				margin: 0 8px;
				span{ margin-right: 0px; }
			}
		}
		.foot-top{
			figure{ 
				margin-bottom: 40px; 
				flex: 0 0 100px;
				max-width: 100%;
			}
			.col{
				flex: 0 0 272px;
				max-width: 272px;
				margin-bottom: 45px;
			}
			.subscribe{
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
		.foot-bot{
			flex-flow: row wrap;
			justify-content: center;
			text-align: center;
			.left-content,.right-content{
				flex: 0 0 100%;
				max-width: 100%;

			}
		}
	}
}